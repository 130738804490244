import React from 'react';
import { Modal } from 'react-responsive-modal';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/models';
import styles from './index.module.scss';

const closeIcon = (
  <svg className="icon" aria-hidden="true">
    <use xlinkHref="#icon-tag_unsubscribe" />
  </svg>
);
const GlobalModal = () => {
  const { isOpen, content } = useSelector((state: RootState) => state.modal);
  const dispatch = useDispatch();
  const close = () => {
    dispatch({ type: 'modal/close' });
  };
  return (
    <Modal
      open={isOpen}
      onClose={close}
      center
      closeIcon={closeIcon}
      classNames={{
        overlay: styles.customOverlay,
        modal: styles.customModal,
        closeButton: styles.customCloseButton,
      }}
    >
      {content}
    </Modal>
  );
};

export default GlobalModal;
