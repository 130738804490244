import ReactGA from 'react-ga';
import { IS_PROD, _GA_ID } from '@/constants/env';

export function gaInitial() {
  try {
    if (IS_PROD && _GA_ID) {
      ReactGA.initialize(_GA_ID);
      pageViewHandle();
    }
  } catch (error) {
    console.warn(`ga error: ${error}`);
  }
}
export function pageViewHandle(path?) {
  path = path || window.location.pathname;
  ReactGA.set({ page: path });
  ReactGA.pageview(path);
}
