import React, { useEffect } from 'react';
import Head from 'next/head';
import { Provider } from 'react-redux';
import type { AppProps } from 'next/app';
import Router, { useRouter } from 'next/router';
import NProgress from 'nprogress';
import 'reflect-metadata';
import { notification, ConfigProvider } from 'antd';
import zhCN from 'antd/lib/locale/zh_CN';
import { ToastContainer } from 'react-toastify';
import { gaInitial, pageViewHandle } from '@/utils/ga';
import { useStore } from '@/store';
import { SITE_IMAGE, SITE_DESCRIPTION, SITE_KEYWORD } from '@/constants/env';
import GlobalModal from '@/components/global-modal';
import 'react-toastify/dist/ReactToastify.css';
import '@/styles/index.scss';
import '@/styles/antd/index.less';
import { HistoryListener } from '@/utils/helpers';
import 'react-responsive-modal/styles.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

/**
 * useStore error bug
 * https://github.com/facebook/react/issues/18178
 */
export default function App({ Component, pageProps }: AppProps) {
  const store = useStore(pageProps?.initialReduxState);
  const router = useRouter();
  useEffect(() => {
    if (history.scrollRestoration) {
      history.scrollRestoration = 'manual';
    }
    HistoryListener(router);
    gaInitial();
    function routeChangeComplete() {
      pageViewHandle();
      NProgress.done();
    }
    function routeChangeError() {
      NProgress.done();
    }
    function routeChangeStart() {
      NProgress.start();
    }
    Router.events.on('routeChangeComplete', routeChangeComplete);
    Router.events.on('routeChangeError', routeChangeError);
    Router.events.on('routeChangeStart', routeChangeStart);
    return () => {
      Router.events.off('routeChangeComplete', routeChangeComplete);
      Router.events.off('routeChangeError', routeChangeError);
      Router.events.off('routeChangeStart', routeChangeStart);
    };
  }, [router]);
  useEffect(() => {
    if (pageProps?.errorMessage) {
      notification.error({
        message: pageProps?.errorMessage,
      });
    }
  }, [pageProps?.errorMessage]);
  return (
    <>
      <Head>
        <meta name="renderer" content="webkit" />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <meta charSet="utf-8" />
        <link rel="shortcut icon" href={SITE_IMAGE} />
        <meta property="og:image" content={SITE_IMAGE} />
        <meta name="description" content={SITE_DESCRIPTION} />
        <meta name="keywords" content={SITE_KEYWORD} />
      </Head>
      <Provider store={store}>
        <ConfigProvider locale={zhCN}>
          <Component {...pageProps} />
        </ConfigProvider>
        <ToastContainer position="top-center" autoClose={3000} />
        <GlobalModal />
      </Provider>
    </>
  );
}

// const initialize = ctx => {
//   const { req, store } = ctx;
//   const userToken = getCookie('USER_TOKEN', req);
//   if (userToken && !store.getState().user.auth.user) {
//     // cookie存在token并且auth.user不存在为null，直接走auth流程即可，判断user是否为空是为了避免每次一路由跳转都走auth流程
//     const payload = {
//       username: getCookie('USER_NAME', req),
//       userId: getCookie('USER_ID', req),
//     }; // 获取相关用户信息存入state
//     store.dispatch(authUserSuccess(payload));
//   }
// };

App.getInitialProps = async ({ Component, ctx }) => {
  let pageProps = {};
  try {
    if (Component.getInitialProps) {
      pageProps = await Component.getInitialProps({ ctx });
    }
    return { pageProps };
  } catch (err) {
    // This will work on both client and server sides.
    console.warn('The Error happened in: ', typeof window === 'undefined' ? 'Server' : 'Client');
    // Sentry.captureException(err);
    return { pageProps };
  }
};
